          
<template>

  <v-card color="rgba(255,255,255,0.01)" :dark="AppisDarkMode" flat height="100%" >
  

  
    
      <v-toolbar dark color="accent" class="stickytopbanner2" width="100%" id="recordtoolbar">
  <v-menu
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
          <v-btn v-on="on" outlined fab small class="elevation-6 mx-1" dark><v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
      </template>
      <v-list  :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }" dark>
     
      <v-list-item @click="ToggleHelp()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Help
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="UpdateAccount()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Save
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="green">mdi-content-save</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-list-item @click="EditAccount()" v-if="CanEdit"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Edit
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-pencil</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>                        
   </v-menu>       
   <v-toolbar-title class="hidden-sm-and-down">
     <span class="headline" >Account - </span><span class="headline font-weight-light">{{ Company_Name  }}</span>
   </v-toolbar-title>
   <v-spacer></v-spacer>       
            <v-btn class="leftpadded" dark v-for="item in BannerTabs" :key="item.itemObjKey" @click="NavigatetoTab(item)" small  text><v-icon small >{{item.Icon}}</v-icon><span class="hidden-sm-and-down">{{item.Name}}</span></v-btn>       
 </v-toolbar> 

  
     <v-parallax class="recordparallax" v-if="AppisDarkMode"
        height="150"
            src="@/assets/RASolidA-Light.jpeg"
        >               
        </v-parallax>
        <v-parallax class="recordparallax" v-if="!AppisDarkMode"
        height="150"
            src="@/assets/RASolidA.jpeg"
        >               
        </v-parallax>
  <LookupComponent :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
    <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>
  <!-- <v-layout row class="stickytopleft mx-3">
  <v-btn @click="ToggleHelp()" color="pop" dark
    fab
    small
    top
    left><v-icon>mdi-help</v-icon></v-btn>
  </v-layout> -->
  <v-layout row class="stickytopright mx-3" id="recordtoolbar2">
    <v-btn @click="UpdateAccount()" color="green" dark
      fab
      small                
      top
      right><v-icon>mdi-content-save</v-icon></v-btn>
  </v-layout>
  

  
  
    

  
    <!--<EmailComponent v-if="previewsendemaildialog" :PresetEmailBody="EmailBody" :PresetEmailFrom="EmailFrom" :PresetEmailTo="EmailTo" :PresetEmailCC="EmailCC" :PresetEmailBCC="EmailBCC" :PresetEmailSubject="EmailSubject" :previewsendemaildialog="previewsendemaildialog"/> -->
    <v-bottom-sheet v-model="helpsheet">
    <v-sheet class="text-center" height="200px">
      <v-btn
        class="mt-6"
        dark
        color="red"
        @click="helpsheet = !helpsheet"
      >close</v-btn>
      <div>This is the Account help sheet. What would you want to know?</div>
      <v-btn @click="ActivateTicketLog()">Log Ticket <v-icon>mdi-ticket-confirmation</v-icon></v-btn>
    </v-sheet>
  </v-bottom-sheet>
<v-card v-if="!EditingAccount"  width="100%" class="stickytopbanner3" id="recordownershipbanner1">
  <v-layout row class="justify-start" id="recordbanner">
      <v-col v-if="Owner">
        <strong>Owner: </strong>{{ Owner.Name+' '+Owner.Surname}}
      </v-col>
      <v-col v-if=" Business_Unit">
        <strong>Business Unit: </strong>{{ Business_Unit.Name}}
      </v-col>
    </v-layout>    
</v-card>
<v-card v-if="EditingAccount"  width="100%" class="stickytopbanner3" id="recordownershipbanner2">
  <v-img
  height="160"
  src="@/assets/RABaseBG.jpeg">
    <v-layout row class="justify-center">
     <h2 class="white--text my-3">Account - Edit View</h2>
    </v-layout>
    <v-card-text>
    <v-text-field v-model="Company_Name" label="Company Name" />
  </v-card-text>
  </v-img>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <strong class="accent--text">Owner:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" @change="AssignBU()" v-model="Owner" return-object></v-autocomplete>
      </v-list-item-content>
      <v-list-item-content>
        <strong class="accent--text">Business Unit:</strong>
      </v-list-item-content>
      <v-list-item-content>
        <v-autocomplete :items="BusinessUnitsArray" item-text="Name" label="Business Unit" readonly v-model="Business_Unit" return-object></v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-card> 
  
  
  

  

  


   <v-layout class="justify-center">
     <v-flex lg11 md12>
   
    
        
          
        
          <v-card height="80px" flat class="transparent">
          </v-card>
                
      
          <v-layout row class="justify-start mx-3"><v-icon color="primary" large>mdi-information</v-icon><span class="secondary--text headline" id="General">General</span></v-layout>
 
           <v-layout row v-if="!EditingAccount">
        <v-col class="my-5 slightmargin" cols="12" md="4" sm="6">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                Section 1<v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection1 = !EditingSection1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Company Name</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Company_Name}}</h4>
                      
                      
                      
                    <span v-if="EditingSection1"><v-text-field  v-model="Company_Name"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Trading As</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      <h4 v-if="!EditingSection1" class="light-blue--text">{{Trading_As}}</h4>
                      
                    <span v-if="EditingSection1"><v-text-field  v-model="Trading_As"></v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Website</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                       <v-text-field v-if="EditingSection1" :rules="[rules.url]" v-model="Website" label="Website"></v-text-field>
                     <a v-if="!EditingSection1" :href="'//'+Website" target="blank">{{Website}}</a>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Business_Phone</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col> 
                       <a :href="'callto:'+Business_Phone" target="_top">{{Business_Phone}}</a>
                    </v-col>
                  </v-row>
                <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Primary Contact</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    
                      
                      
                      
                      <router-link :to="'/Contact/'+Primary_Contact.id" v-if="Primary_Contact"><h4 v-if="!EditingSection1" class="light-blue--text">{{Primary_Contact.Full_Name}}</h4></router-link><a v-if="!Primary_Contact && HasPrimary_Contact" class="unpermitted"><h4 v-if="!EditingSection1" class="light-blue--text">{{UnpermittedPrimary_Contact.Full_Name}}</h4><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedPrimary_Contact.Full_Name }}"</p></a>
                    <span v-if="EditingSection1"><v-combobox
                        v-if="Primary_Contact || !Primary_Contact && !HasPrimary_Contact"
                        v-model="Primary_Contact"
                        :items="Primary_ContactArray"
                        item-text="Full_Name"
                        hide-selected
                         label="Primary Contact"
                        small-chips
                        solo
                        flat
                        >
                        <template v-slot:prepend-item>
                            <v-list>                            
                              <v-list-item>
                                <v-list-item-content>
                                  <v-btn small @click="PrepareLinkExistingPrimary_Contact()" color="warning" dark text>Lookup More</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn small @click="ActivateAddNewPrimary_ContactDialog()" color="green" dark text>Add New</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </template></v-combobox>
                        <div  class="unpermitted" v-if="!Primary_Contact && HasPrimary_Contact">
                        <v-combobox
                        v-model="UnpermittedPrimary_Contact"
                        readonly
                        :items="Primary_ContactArray"
                        item-text="Full_Name"
                        hide-selected
                         label="Primary Contact"
                        small-chips
                        solo
                        flat
                        ></v-combobox><p class="tooltiptext">You do not have access to the current linked record "{{ UnpermittedPrimary_Contact.Full_Name }}", and therefore cannot change the linked record</p></div>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Business Email</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                     <a :href="'mailto:'+Business_Email" target="_top">{{Business_Email}}</a>
                    </v-col>
                  </v-row>
                </v-card>
            </v-card>
          </v-col>
        <v-col class="my-5 slightmargin" cols="12" md="4" sm="6">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                Section 2<v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection2 = !EditingSection2">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Activities</h3>
                  </v-layout>
                  </v-card-subtitle>
                  <v-expansion-panels class="activitytabs">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Tasks</v-expansion-panel-header>
                    <v-expansion-panel-content>
                       <RecordTasks :Business_Unit="Business_Unit" :UserRecord="UserRecord" :UsersArray="UsersArray" :RegardingTypefilter="RegardingTypefilter" :ActivityTypefilter="ActivityTypefilter" :RelatedObj="RelatedObj" :RecordPrimaryField="Company_Name" :ActivitiesArray="ActivitiesArray" />
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Appointments</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <Appointments :RelatedObj="RelatedObj" :UserRecord="UserRecord" :UsersArray="UsersArray" :AppointmentsQuery="AppointmentsQuery" :Business_Unit="Business_Unit" :datasetsecuritystructure="'Business Unit'"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Emails</v-expansion-panel-header>
                        <v-expansion-panel-content>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Phone Calls</v-expansion-panel-header>
                        <v-expansion-panel-content>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Notes</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                              <v-btn
                                  fab
                                  color="primary"
                                  right
                                  absolute
                                  small
                                  @click="AddNote()"
                                ><v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                            <v-list-item v-for="note in NotesArray" :key="note.itemObjKey" class="notes">
                              <v-list-item-content>
                                {{note.Title}}
                              </v-list-item-content>
                              <v-list-item-content>
                                {{note.Owner.v}}
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-menu offset-y :close-on-content-click="false" bottom left>
                                  <template v-slot:activator="{ on }">
                                      <v-icon
                                      color="blue"
                                      v-on="on" small>
                                      mdi-eye
                                    </v-icon>
                                  </template>
                                  <v-list min-width=300px max-width=500px :color="note.Color" :dark="note.Dark">
                                    <v-menu offset-y :close-on-content-click="false" bottom left>
                                        <template v-slot:activator="{ on }">
                                             <v-btn
                                              fab
                                              right
                                              absolute
                                              small

                                                ><v-icon
                                                color="blue"
                                                v-on="on" small>
                                                mdi-pencil
                                              </v-icon>
                                             </v-btn>
                                          </template>
                                            <v-list min-width=300px max-width=500px :color="note.Color" :dark="note.Dark">
                                              <v-icon
                                                  @click="ToggleNoteDark(note)"
                                                  color="blue"
                                                  small>
                                                  mdi-invert-colors
                                                </v-icon>
                                              <v-menu offset-y :close-on-content-click="false" bottom left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                      fab
                                                      right
                                                      absolute
                                                      small

                                                        ><v-icon
                                                        color="blue"
                                                        v-on="on" small>
                                                        mdi-palette
                                                      </v-icon>
                                                    </v-btn>
                                                  </template>
                                              <input type="color" id="notebgcolor" v-model="note.Color" @change="LightenColor(note)" />
                                              </v-menu>


                                              <body :bgcolor="note.TitleColor"><h4><v-text-field v-model="note.Title"></v-text-field></h4><br></body>
                                              <v-list-item>
                                              <v-list-item-content>
                                                <v-textarea v-model="note.Description"></v-textarea>
                                              </v-list-item-content>
                                              </v-list-item>
                                               <v-btn @click="UpdateNotesArray()" v-model="note.Description">Save </v-btn>
                                            </v-list>
                                        </v-menu>
                                    <body :bgcolor="note.TitleColor"><h4>{{note.Title}}</h4><br></body>

                                    <v-list-item>
                                      <v-list-item-content>
                                              {{note.Description}}
                                      </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                  </v-menu>
                              </v-list-item-content>

                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
            </v-card>
          </v-col>
        <v-col class="my-5 slightmargin" cols="12" md="4" sm="6">
          <v-card class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 accent text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                Section 3<v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit" @click="EditingSection3 = !EditingSection3">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card height="100%" shaped>
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">PO Box</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    </v-col>
                  </v-row>
                    <v-list dense class="outline">
                    <v-list-item>
                      <v-list-item-content>
                      Street Number:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxStreet_Number}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxStreet_Number" label="Street Number">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Street Name:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxStreet_Name}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxStreet_Name" label="Street Name">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Suburb:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxSuburb}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxSuburb" label="Suburb">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      City:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxCity}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxCity" label="City">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Postal Code:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxPostal_Code}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxPostal_Code" label="Postal Code">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      State Province:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxState_Province}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxState_Province" label="State Province">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Country Region:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{PO_BoxCountry_Region}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="PO_BoxCountry_Region" label="Country Region">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                </v-list>
                  
                  <v-row class=" mx-3">
                    <v-col row>
                      <h4 :class="HeaderDarkLightStyle">Address</h4>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                    </v-col>
                  </v-row>
                    <v-list dense class="outline">
                    <v-list-item>
                      <v-list-item-content>
                      Street Number:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressStreet_Number}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressStreet_Number" label="Street Number">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Street Name:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressStreet_Name}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressStreet_Name" label="Street Name">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Suburb:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressSuburb}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressSuburb" label="Suburb">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      City:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressCity}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressCity" label="City">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Postal Code:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressPostal_Code}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressPostal_Code" label="Postal Code">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      State Province:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressState_Province}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressState_Province" label="State Province">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                      Country Region:
                      </v-list-item-content>
                      <v-list-item-content  v-if="!EditingSection3">
                      {{AddressCountry_Region}}
                      </v-list-item-content>
                      <v-list-item-content  v-if="EditingSection3">
                      <v-text-field v-model="AddressCountry_Region" label="Country Region">
                      </v-text-field>
                      </v-list-item-content>
                  </v-list-item>
                  <br>
                  <v-list-item v-if="AddressStreet_Number && AddressStreet_Name && AddressSuburb && AddressCity && AddressPostal_Code && AddressState_Province && AddressCountry_Region">
                        <v-layout class="justify-start">
                          <h4>View on Google Maps</h4>
                        </v-layout>
                        <v-layout class="justify-end">
                          <v-btn icon @click="ViewMapItem(AddressStreet_Number,AddressStreet_Name,AddressSuburb,AddressCity,AddressPostal_Code,AddressState_Province,AddressCountry_Region)"><v-icon>mdi-google-maps</v-icon></v-btn>
                        </v-layout>
                    </v-list-item>
                </v-list>
                  
                </v-card>
            </v-card>
          </v-col>
          </v-layout>
    
          
          
        
          
        
          
    <!-- RMADmin here -->
    
          <v-card height="80px" flat class="transparent" v-if="userIsAdmin">
          </v-card>
          
          
           <v-card class="folderyellow  yellow lighten-4" shaped id="Documents"  v-if="userIsAdmin">    
              <v-card-title class="align-center secondary white--text">
                <span class="headline">Unsorted Documents</span>
              </v-card-title>
            </v-card>
             <v-card class="my-1" color="rgba(255,255,255,0.6)" outlined  v-if="userIsAdmin">
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>

         
            <v-tabs background-color="transparent"  color="white">
              <v-tab class="foldertab">Unsorted Documents</v-tab>
           <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Document Register'" :PushedUser="UserRecord"/>
            </v-tab-item>
            <v-tab class="foldertab">Unsorted Photos</v-tab>
            <v-tab-item class="paddedtab">            
            <RMAdministration v-if="selectedRecord && selectedRecord.id" :RecordselectedRecord="selectedRecord" :RecordselectedEntity="selectedEntity" :TableType="'Images'" :PushedUser="UserRecord"/>
            </v-tab-item>
           </v-tabs>
             </v-card>

          <!-- RMADmin here -->

      
      </v-flex>
  </v-layout>
     <v-layout column class="fab-container" id="myBtn">
    <v-btn fab @click="topFunction()" dark color="red">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-layout>
  

  
  </v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SystemTicketsLogging from '@/components/SystemTicketsLogging'
import LookupComponent from '@/components/LookupComponent'
import Appointments from '@/components/Appointments'
import RecordTasks from '@/components/RecordTasks'
import RMAdministration from '@/components/System/RMAdministration'

export default {
  props: ['AppisDarkMode'],
components: {
  RMAdministration,
  SystemTicketsLogging,
  LookupComponent,
    Appointments,
    RecordTasks
},
    

  data() {
    return {
      EditingPermissionsGeneral: false,
      CanCreate: false,
      CanGet: false,
      CanList: false,
      CanEdit: false,
      CanDelete: false,
      
      selectedEntity: {Name: 'Accounts',
      PrimaryField: 'Company_Name',
      Tables: [
          ]
      },
      selectedRecord: {},
      BannerTabs: [
        ],
      EditingSection1: false,
      EditingSection2: false,
      EditingSection3: false,
      TicketLog: false,
      step: 1,
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
      previewsendemaildialog: false,
      EmailBody: '',
      EmailFrom: '',
      EmailTo: '',
      EmailCC: '',
      EmailBCC: '',
      EmailSubject: '',
      STDAppointmentsQuery: db.collection('activities').where('activitytype','==','Appointment').where('regardingtype', '==', 'Account').where('regardingrecord.id', '==', this.$route.params.id),
      AppointmentsQuery: '',
      ActivitiesArray: [],
      NotesArray: [],
      Company_Name: '',
      Trading_As: '',
      Created_On: '',
      Created_Onmenu : false,
      Business_Unit:  {id: '', Name: ''},
      UnpermittedBusiness_Unit:  {id: '', Name: ''},
      HasBusiness_Unit: false,
      Business_UnitRelatedParentObj: {},
      Created_By:  {id: '', Full_Name: ''},
      UnpermittedCreated_By:  {id: '', Full_Name: ''},
      HasCreated_By: false,
      Created_ByRelatedParentObj: {},
      Owner:  {id: '', Full_Name: ''},
      UnpermittedOwner:  {id: '', Full_Name: ''},
      HasOwner: false,
      OwnerRelatedParentObj: {},
      Primary_Contact:  {id: '', Full_Name: ''},
      UnpermittedPrimary_Contact:  {id: '', Full_Name: ''},
      HasPrimary_Contact: false,
      Primary_ContactRelatedParentObj: {},
      AddressStreet_Number:  '',
      AddressStreet_Name:  '',
      AddressSuburb:  '',
      AddressCity:  '',
      AddressPostal_Code:  '',
      AddressState_Province:  '',
      AddressCountry_Region:  '',
      Business_Email:  '',
      Business_Phone:  '',
      PO_BoxStreet_Number:  '',
      PO_BoxStreet_Name:  '',
      PO_BoxSuburb:  '',
      PO_BoxCity:  '',
      PO_BoxPostal_Code:  '',
      PO_BoxState_Province:  '',
      PO_BoxCountry_Region:  '',
      Website:  '',
      Mobile_Number: '',
      Business_UnitName: null,
      Created_ByFull_Name: null,
      OwnerFull_Name: null,
      Primary_ContactFull_Name: null,
      
      Created_Onmenu: false,
      
      
      
      AddNewParentLookupDialog: false,
      ViewExistingLookupDialog: false,
      FieldFilter: '',
      ParentTitle: '',
      ParentCollection:  '',
      NewParentPrimary: '',
      ParentLookup: '',
      NewParentAdditional: [],
      LookupArray: [],
      LookupArrayHeaders: [],
      Business_UnitArray: [],
      Business_Unitfilter: {Name: ''},
      
      Created_ByArray: [],
      Created_Byfilter: {Full_Name: ''},
      
      OwnerArray: [],
      Ownerfilter: {Full_Name: ''},
      
      Primary_ContactArray: [],
      Primary_Contactfilter: {Full_Name: ''},
      
      EditingAccount: false,
      dialog: false,
      initialize: '',
      search: '',
      AccountData: {},
      headers: [
      
        
        { text: 'Company Name', value: 'Company_Name'},
        
        
        { text: 'Trading As', value: 'Trading_As'},
        
        
        
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        
        
        { text: 'Owner', value: 'Owner.Full_Name'},
        
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedAccountsnackbar: false,
      AccountEditdialog: false,
      newAccountdialog: false,
      undefined: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
      },
      editedItem: {
        id: '',
      },
      UsersArray:[],
      BusinessUnitsArray: [],
      
      Manager: {Name: '', Surname: '', id: '', Full_Name: ''},
      
      Name:  '',
      Description:  '',
      Parent:  {id: '', Name: ''},
      Parentname: null,
      ParentArray: [],
      Parentfilter: {Name: ''},
      EditingBusinessUnit: false,
      ParentsToUpdate: [],
      ChildArrayQuery: [],
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      IsAdmin: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "Default get All",
  "Default All"
],
      UnitDowngetRoles: [
  "Default Unit Down",
  "Default get UnitDown"
],
      UserUnitgetRoles: [
  "Default Unit",
  "Default get UserUnit"
],
      OwnergetRoles: [
  "Default Owner"
],
      AlllistRoles: [
  "Default list All",
  "Default All"
],
      UnitDownlistRoles: [
  "Default list UnitDown",
  "Default Unit Down"
],
      UserUnitlistRoles: [
  "Default Unit",
  "Default list UserUnit"
],
      OwnerlistRoles: [
  "Default Owner"
],
      AllcreateRoles: [
  "Default Create All",
  "Default All"
],
      UnitDowncreateRoles: [
  "Default create unitdown",
  "Default Unit Down"
],
      UserUnitcreateRoles: [
  "Default Unit",
  "Default create UserUnit"
],
      OwnercreateRoles: [
  "Default Owner"
],
      AllupdateRoles: [
  "Default update ALL",
  "Default All"
],
      UnitDownupdateRoles: [
  "Default update Unit Down",
  "Default Unit Down"
],
      UserUnitupdateRoles: [
  "Default Unit",
  "Default update UserUnit"
],
      OwnerupdateRoles: [
  "Default Owner"
],
      AlldeleteRoles: [
  "Default delete ALL",
  "Default All"
],
      UnitDowndeleteRoles: [
  "Default delete UnitDown",
  "Default Unit Down"
],
      UserUnitdeleteRoles: [
  "Default Unit",
  "Default delete UserUnit"
],
      OwnerdeleteRoles: [
  "Default Owner"
],
      helpsheet: false,
      UndefinedMandatoryFields: [],
      RelatedObj: {},
      
    }
    
    
  },

  created(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.IntranetViewToggle(false)


this.GetRequestingUser()
this.GetUsers()
this.GetBusinessUnits()
window.addEventListener('scroll', this.handleScroll)

  },

  computed: {
      userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
        return true
      }
    }
  
},
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    BusinessUnitsStore(){
      return this.$store.state.BusinessUnitsArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    ContactsStore(){
      return this.$store.state.ContactsArray
    },
    
    HeaderDarkLightStyle(){
      if(this.AppisDarkMode){
        return 'white--text font-weight-light'
      }
      else{
        return 'primary--text font-weight-light'
      }
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
  Created_OnObject(){
    return this.DateFormatter(this.Created_On)
  },
  ActivitiesSearched() {
    return this.ActivitiesArray.filter(Activity => {
      if(Activity.Name){return Activity.Name.toLowerCase().includes(this.search.toLowerCase())}}).filter(Activity => {if(Activity.description){return Activity.description.toLowerCase().includes(this.search.toLowerCase())}
    })
    },
    ActivitiesFilteredandSearched() {
    return this.ActivitiesSearched.filter(Activity => {if(this.ActivityTypefilter){if(Activity.activitytype){ return Activity.activitytype.includes(this.ActivityTypefilter)}} else {return Activity}}).filter(Activity => {if(this.RegardingTypefilter){if(Activity.regardingtype){ return Activity.regardingtype.includes(this.RegardingTypefilter)}} else {return Activity}})
    },
    TasksFilteredandSearched(){
        return this.ActivitiesFilteredandSearched.filter(activity => {
            return activity.activitytype === 'Task' && this.DateFilterString(activity.duedate) === this.FormattedTextDate && activity.status.includes(this.TaskStatusFilter)
        })
    },
    TasksFilteredandSearchedTable(){
        return this.TasksFilteredandSearched.filter(activity => {
            return activity.duedate === this.TaskFilterDate
        })
    },
    FormattedPickDate(){
      return this.DateFormatter(this.TaskFilterDate)
    },
    FormattedTextDate(){
      return this.TaskFilterDate.split('-').join('')
    },  
  
          
    },
    watch: {
    userLoggedIn (value) {
      if (value !== null && value !== undefined) {
        //this.$router.push('/')
      }
    }
  },
  methods: {
    Documentoptions(value,array){
        if(value.ID){
          let option = array.find(obj => obj.ID === value.ID)
        return option.Name
        }
        else{
          return value +'OLD'
        }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
    
      
    async GetRequestingUser(){
      
        var vm = this;
      //await firebase.auth().onAuthStateChanged(function(user) {
        if (this.userLoggedIn) {

            //db.collection('users').doc(user.uid).onSnapshot(snapshot => {
            //  var userdetails = snapshot.data()

              vm.UserRecord = this.userLoggedIn
              vm.UserRoles = this.userLoggedIn.rolesarrayQuery
              let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              console.log(vm.userBU)
              // if(vm.UserRecord.Business_Unitid){
                  vm.UserBusinessUnitID = vm.userBU.id
                  console.log(vm.UserBusinessUnitID)
                  // db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
                  // var businessunitdata = snapshot.data()
                  vm.UserBusUnitChildren = vm.userBU.childarrayQuery
                  vm.UserBusUnitParents = vm.userBU.parentarray
                  console.log(vm.UserBusUnitChildren)
              //     })
              // }
              
              
              vm.UserRoles = vm.UserRecord.rolesarrayQuery
              vm.UserBusinessUnitID = vm.UserRecord.Business_Unitid
              console.log(vm.UserBusinessUnitID)
              db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             console.log(vm.UserRoles)
             let AdminTest = vm.UserRoles.find(obj => obj === 'System Admin')
                if(typeof AdminTest !== 'undefined'){
                  vm.IsAdmin = true
                }
              
              let ActivitiesCollectionReference = db.collection('activities')
              let ActivitiesstandardQuery = ActivitiesCollectionReference.where('regardingtype', '==', 'Account').where('regardingrecord.id', '==', vm.$route.params.id)
              vm.BURolesCheck(vm.AllgetRoles,vm.UnitDowngetRoles,vm.UserUnitgetRoles,vm.OwnergetRoles).then(PermissionLevel => {
                console.log(PermissionLevel)
                if(PermissionLevel === 4 || vm.IsAdmin === true){
                  vm.listAll = true
                  console.log('Permissions for Accounts is All')
                  vm.GetAccountBUCheck()
                  vm.AppointmentsQuery = vm.STDAppointmentsQuery
                  let ActivityRegardingQuery =  ActivitiesstandardQuery
                  vm.GetActivities(ActivityRegardingQuery)
                }
                else if(PermissionLevel === 3){
                  vm.listUnitDown = true
                  console.log('Permissions for Accounts is UnitDown')
                  vm.GetAccountBUCheck()
                  vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Business_Unitid', 'in', vm.UserBusUnitChildren);
                  let ActivityRegardingQuery =  ActivitiesstandardQuery.where('Business_Unitid', 'in', vm.UserBusUnitChildren);
                  vm.GetActivities(ActivityRegardingQuery)
                }
                else if(PermissionLevel === 2){
                  vm.listUserUnit = true
                  console.log('Permissions for Accounts is UserUnit')
                  vm.GetAccountBUCheck()
                    vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Business_Unitid', '==', vm.UserBusinessUnitID)
                  let ActivityRegardingQuery =  ActivitiesstandardQuery.where('Business_Unitid', '==', vm.UserBusinessUnitID);
                  vm.GetActivities(ActivityRegardingQuery)
                }
                else if(PermissionLevel === 1 || vm.IsAdmin){
                  vm.listOwner = true
                  vm.AppointmentsQuery = vm.STDAppointmentsQuery.where('Ownerid', '==', vm.UserRecord.id)
                  console.log('Permissions for Accounts is Owner')
                  vm.GetAccountBUCheck()
                  let ActivityRegardingQuery =  ActivitiesstandardQuery.where('Ownerid', '==', vm.UserRecord.id);
                  vm.GetActivities(ActivityRegardingQuery)
                }
                else {
                  vm.RoutetoLoginPage()
                    }
              })              
              
                    
              let BusinessUnitsBusiness_UnitQuery = db.collection('businessunits')
              vm.GetBusinessUnitsBusiness_UnitArrayandLookupValue(BusinessUnitsBusiness_UnitQuery)
              

                
                    
              let UsersCreated_ByQuery = db.collection('users')
              vm.GetUsersCreated_ByArrayandLookupValue(UsersCreated_ByQuery)
              

                
                    
              let UsersOwnerQuery = db.collection('users')
              vm.GetUsersOwnerArrayandLookupValue(UsersOwnerQuery)
              

                
                    
            
              let allContactsPrimary_ContactlistRoles = [
  "Default All",
  "Default list All"
]
              let peerContactsPrimary_ContactlistRoles = [
  "Default Unit Down",
  "Default list UnitDown"
]
              let parentContactsPrimary_ContactlistRoles = [
  "Default Unit",
  "Default list UserUnit"
]
              let childContactsPrimary_ContactlistRoles = [
  "Default Owner"
]
              let ContactsPrimary_ContactCollectionReference = db.collection('contacts')
              vm.BURolesCheck(allContactsPrimary_ContactlistRoles,peerContactsPrimary_ContactlistRoles,parentContactsPrimary_ContactlistRoles,childContactsPrimary_ContactlistRoles).then(PermissionLevel => {
                  console.log(PermissionLevel)
                  if(PermissionLevel === 4 || vm.IsAdmin === true){
                  let ContactsPrimary_ContactQuery =  ContactsPrimary_ContactCollectionReference
                  vm.GetContactsPrimary_ContactArrayandLookupValue(ContactsPrimary_ContactQuery)
                }
                else if(PermissionLevel === 3){
                  let ContactsPrimary_ContactQuery =  ContactsPrimary_ContactCollectionReference.where('Business_Unitid', 'in', vm.UserBusUnitChildren);
                  vm.GetContactsPrimary_ContactArrayandLookupValue(ContactsPrimary_ContactQuery)
                }
                else if(PermissionLevel === 2){
                  let ContactsPrimary_ContactQuery =  ContactsPrimary_ContactCollectionReference.where('Business_Unitid', '==', vm.UserBusinessUnitID);
                  vm.GetContactsPrimary_ContactArrayandLookupValue(ContactsPrimary_ContactQuery)
                }
                else if(PermissionLevel === 1){
                  let ContactsPrimary_ContactQuery =  ContactsPrimary_ContactCollectionReference.where('Ownerid', '==', vm.UserRecord.id);
                  vm.GetContactsPrimary_ContactArrayandLookupValue(ContactsPrimary_ContactQuery)
                }
                else {
                  //vm.RoutetoErrorPage()
                }
                })

                              
              })
            //})
           
          }
      else {
        vm.RoutetoLoginPage()
          }
          //})
      },
      CustomRolesCheck(Roles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      BURolesCheck(AllRoles, PeerRoles, ParentRoles, ChildRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = vm.UserRoles.length
        console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        vm.UserRoles.map((role,i) => {
          console.log(i)
              let Alltest = AllRoles.find(obj => obj == role)
              let Peertest = PeerRoles.find(obj => obj == role)
              let Parenttest = ParentRoles.find(obj => obj == role)
              let Childtest = ChildRoles.find(obj => obj == role)
                if(Alltest){
                  PermissionLevel = 4
                }                
                else if(Peertest){
                  if(PermissionLevel<3){
                    PermissionLevel = 3
                  }
                }                  
                else if(Parenttest){
                  if(PermissionLevel<2){
                    PermissionLevel = 2
                  }
                } 
                else if(Childtest){
                  if(PermissionLevel<1){
                    PermissionLevel = 1
                  }
                  
                } 
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      GetAccount() {
        let vm = this
        if(vm.IsAdmin){
          vm.CanEdit = true
        }
            db.collection('accounts').doc(this.$route.params.id).onSnapshot(snapshot => {
            var account = snapshot.data()
            account.id = this.$route.params.id
            
            this.selectedRecord = {id: this.$route.params.id, Company_Name: account.Company_Name }
            this.RelatedObj = {id: this.$route.params.id, Company_Name: account.Company_Name,identifier: 'Company_Name',collection: 'accounts',Link:'Account' }
                  let ActivitiesCollectionReference = db.collection('activities')
                  let ActivitiesstandardQuery = ActivitiesCollectionReference.where('regardingtype', '==', 'Account').where('regardingrecord.id', '==', vm.$route.params.id)
                  let ActivityRegardingQuery =  ActivitiesstandardQuery;
                  vm.GetActivities(ActivityRegardingQuery)
            this.AccountData = account
            this.AccountData.id = this.$route.params.id
            this.$emit('PushRecordName',this.AccountData.Company_Name)
            if(typeof account.Owner !== 'undefined'){
              let tempOwner = account.Owner
              this.Owner = this.UsersArray.find(user => user.id == tempOwner.id)
            }
            
            if(typeof account.notesarray !== 'undefined'){
            this.NotesArray = account.notesarray
              }
            if(typeof account.Owner !== 'undefined'){
              this.Business_Unit = account.Business_Unit
            }        

            this.Company_Name = account.Company_Name
            this.editedItem.Company_Name = account.Company_Name
            //I think API Trigger should be here, outside the first if loop on fields
            this.Trading_As = account.Trading_As
            this.editedItem.Trading_As = account.Trading_As
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
              if(account.Created_On && typeof account.Created_On !== 'string'){
            this.Created_On = format(account.Created_On.toDate(),'yyyy-MM-dd')
            this.editedItem.Created_On = account.Created_On
              }
            if(account.Business_Unit){
            this.Business_UnitRelatedParentObj = {id: account.Business_Unit.id, Company_Name: account.Company_Name,identifier: 'Name',collection: 'businessunits',Link: 'BusinessUnit' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === account.Business_Unitid)
            if(typeof account.Business_Unitid !== 'undefined' && account.Business_Unitid !== ''){
              this.HasBusiness_Unit = true
              if(typeof this.Business_Unit === 'undefined'){
                this.UnpermittedBusiness_Unit = {id: account.Business_Unitid,Name: account.Business_Unit.Name}
              }
            }
            this.editedItem.Business_Unit = account.Business_Unit
            if(account.Created_By){
            this.Created_ByRelatedParentObj = {id: account.Created_By.id, Company_Name: account.Company_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Created_By = this.Created_ByArray.find(created_by => created_by.id === account.Created_Byid)
            if(typeof account.Created_Byid !== 'undefined' && account.Created_Byid !== ''){
              this.HasCreated_By = true
              if(typeof this.Created_By === 'undefined'){
                this.UnpermittedCreated_By = {id: account.Created_Byid,Full_Name: account.Created_By.Full_Name}
              }
            }
            this.editedItem.Created_By = account.Created_By
            if(account.Owner){
            this.OwnerRelatedParentObj = {id: account.Owner.id, Company_Name: account.Company_Name,identifier: 'Full_Name',collection: 'users',Link: 'User' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Owner = this.OwnerArray.find(owner => owner.id === account.Ownerid)
            if(typeof account.Ownerid !== 'undefined' && account.Ownerid !== ''){
              this.HasOwner = true
              if(typeof this.Owner === 'undefined'){
                this.UnpermittedOwner = {id: account.Ownerid,Full_Name: account.Owner.Full_Name}
              }
            }
            this.editedItem.Owner = account.Owner
            if(account.Primary_Contact){
            this.Primary_ContactRelatedParentObj = {id: account.Primary_Contact.id, Company_Name: account.Company_Name,identifier: 'Full_Name',collection: 'contacts',Link: 'Contact' }
              }
            //I think API Trigger should be here, outside the first if loop on fields
            this.Primary_Contact = this.Primary_ContactArray.find(primary_contact => primary_contact.id === account.Primary_Contactid)
            if(typeof account.Primary_Contactid !== 'undefined' && account.Primary_Contactid !== ''){
              this.HasPrimary_Contact = true
              if(typeof this.Primary_Contact === 'undefined'){
                this.UnpermittedPrimary_Contact = {id: account.Primary_Contactid,Full_Name: account.Primary_Contact.Full_Name}
              }
            }
            this.editedItem.Primary_Contact = account.Primary_Contact
            //I think API Trigger should be here, outside the first if loop on fields
            this.AddressStreet_Number = account.AddressStreet_Number
            this.AddressStreet_Name = account.AddressStreet_Name
            this.AddressSuburb = account.AddressSuburb
            this.AddressCity = account.AddressCity
            this.AddressPostal_Code = account.AddressPostal_Code
            this.AddressState_Province = account.AddressState_Province
            this.AddressCountry_Region = account.AddressCountry_Region
            this.Business_Email = account.Business_Email
            this.editedItem.Business_Email = account.Business_Email
            //I think API Trigger should be here, outside the first if loop on fields
            this.Business_Phone = account.Business_Phone
            this.editedItem.Business_Phone = account.Business_Phone
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
            this.PO_BoxStreet_Number = account.PO_BoxStreet_Number
            this.PO_BoxStreet_Name = account.PO_BoxStreet_Name
            this.PO_BoxSuburb = account.PO_BoxSuburb
            this.PO_BoxCity = account.PO_BoxCity
            this.PO_BoxPostal_Code = account.PO_BoxPostal_Code
            this.PO_BoxState_Province = account.PO_BoxState_Province
            this.PO_BoxCountry_Region = account.PO_BoxCountry_Region
            this.Website = account.Website
            this.editedItem.Website = account.Website
            //I think API Trigger should be here, outside the first if loop on fields
            //I think API Trigger should be here, outside the first if loop on fields
    
            })
          
        },
      
      
      GetAccountBUCheck() {
        if(this.IsAdmin === false && this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){
          this.RoutetoErrorPage()
        }
    
      else {
      this.GetAccount()
      }
  },


    
      
      
      
      getImgUrlRefDocWiki(item) {
        var images = require.context('@/assets/', false)
          if(item.FileType.includes('pdf')){
            return images('./PDF_DOC.png')
          }
          else{
            return images('./logo.png')
          }    
        },
      RelatedRecordsArray(array,arrayname,headers,headersname){
      console.log('getting related records ',arrayname)
      this[arrayname] = array
      this[headersname] = headers
    },
      DateFormatterwithTimeXML(date){
        if(date){
              console.log(date)
              console.log(typeof date)
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2].split('T')[0]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = date.split('-')[2].split('T')[1].split(':')[0]
              var min = date.split('-')[2].split('T')[1].split(':')[1]
              var sec = date.split('-')[2].split('T')[1].split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
          
              console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
              return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec)
              }
            else{
              return null
            }
      },
      
        UpdateAccount(){
        this.UndefinedMandatoryFields = []
      const EditedAccount = {
    Company_Name: this.Company_Name,
    
    //here we should now make room for mandatory lookupfields
        Business_Unit: {Name: this.Business_Unit.Name,id: this.Business_Unit.id},
        Business_Unitid: this.Business_Unit.id,
    
    //here we should now make room for mandatory lookupfields
        Owner: {Full_Name: this.Owner.Full_Name,id: this.Owner.id},
        Ownerid: this.Owner.id,
    
        }
      for(var prop in EditedAccount) {
          if (EditedAccount.hasOwnProperty(prop)) {
            if(typeof EditedAccount[prop] === 'undefined'){
              this.UndefinedMandatoryFields.push(prop)
            }
          else {
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
            }
            }
      
          else{
            console.log('something is amook')
          }
        }
        console.log(this.UndefinedMandatoryFields)
      
      //not sure why we have this... 
      //const EditedUser = this.Owner
      
      //this below database "update" is applicable to the update of mandatory fields
      if(this.UndefinedMandatoryFields.length === 0){
        if(this.Primary_Contact && this.Primary_Contact.id){
        this.GetMobile_Number(this.Primary_Contact.id)
        }
      db.collection('accounts').doc(this.$route.params.id).update({
        Company_Name: EditedAccount.Company_Name,
        
    //here we should now make room for mandatory lookupfields
        Business_Unit: EditedAccount.Business_Unit,
        Business_Unitid: EditedAccount.Business_Unitid,
                  
        
    //here we should now make room for mandatory lookupfields
        Owner: EditedAccount.Owner,
        Ownerid: EditedAccount.Ownerid,
                  
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date(),
        
              });
              this.close()
              this.EditedAccountsnackbar = true
              this.CancelAccountEditing()
              //exactly what we did here, what we incorrectly did here with lookupfields, we will do with optional fields. So this shoudl change to a v-for on optional fields, and same logic, if undefined on each, then nothing otherwise update record with said field
              if(typeof this.Company_Name !== 'undefined'){                  
                  let Company_NameQuery = []
                  let Company_NameStringArray = this.Company_Name.split('')
                  var lowertext = "";                
                  var p;
                    let buildup = ''
                   console.log(Company_NameStringArray.length,Company_NameStringArray)
                    for (p = 0; p < Company_NameStringArray.length; p++) {
                        buildup = buildup+ Company_NameStringArray[p]
                          lowertext += buildup.toLowerCase() + ",";
                          }                   
                    Company_NameQuery = lowertext.split(',')  
                    Company_NameQuery.length = Company_NameQuery.length-1
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Company_NameQuery: Company_NameQuery,
                    Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                    Modified_Byid: this.UserRecord.id,
                    Modified_On: new Date()
                     })
                 }  
              
                if(typeof this.Trading_As !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Trading_As: this.Trading_As,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                
              if(typeof this.Primary_Contact !== 'undefined'){
                db.collection('accounts').doc(this.$route.params.id).update({
                  Primary_Contact: {Full_Name: this.Primary_Contact.Full_Name,id: this.Primary_Contact.id},
                  Primary_Contactid: this.Primary_Contact.id,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                      })
              }
                if(typeof this.Address !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Address: this.Address,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                   if(typeof this.AddressStreet_Number !== 'undefined' && this.AddressStreet_Number !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressStreet_Number: this.AddressStreet_Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressStreet_Name !== 'undefined' && this.AddressStreet_Name !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressStreet_Name: this.AddressStreet_Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressSuburb !== 'undefined' && this.AddressSuburb !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressSuburb: this.AddressSuburb,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressCity !== 'undefined' && this.AddressCity !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressCity: this.AddressCity,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressPostal_Code !== 'undefined' && this.AddressPostal_Code !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressPostal_Code: this.AddressPostal_Code,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressState_Province !== 'undefined' && this.AddressState_Province !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressState_Province: this.AddressState_Province,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.AddressCountry_Region !== 'undefined' && this.AddressCountry_Region !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  AddressCountry_Region: this.AddressCountry_Region,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                if(typeof this.Business_Email !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Business_Email: this.Business_Email,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.Business_Phone !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Business_Phone: this.Business_Phone,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                if(typeof this.PO_Box !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     PO_Box: this.PO_Box,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                   if(typeof this.PO_BoxStreet_Number !== 'undefined' && this.PO_BoxStreet_Number !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxStreet_Number: this.PO_BoxStreet_Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxStreet_Name !== 'undefined' && this.PO_BoxStreet_Name !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxStreet_Name: this.PO_BoxStreet_Name,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxSuburb !== 'undefined' && this.PO_BoxSuburb !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxSuburb: this.PO_BoxSuburb,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxCity !== 'undefined' && this.PO_BoxCity !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxCity: this.PO_BoxCity,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxPostal_Code !== 'undefined' && this.PO_BoxPostal_Code !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxPostal_Code: this.PO_BoxPostal_Code,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxState_Province !== 'undefined' && this.PO_BoxState_Province !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxState_Province: this.PO_BoxState_Province,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                   if(typeof this.PO_BoxCountry_Region !== 'undefined' && this.PO_BoxCountry_Region !== ''){
                    db.collection('accounts').doc(this.$route.params.id).update({
                  PO_BoxCountry_Region: this.PO_BoxCountry_Region,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                    })
                   }
                if(typeof this.Website !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Website: this.Website,
                      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                      Modified_Byid: this.UserRecord.id,
                      Modified_On: new Date()
                     })
                 }
                
                
                 if(this.Primary_Contact && typeof this.Primary_Contact.Mobile_Number !== 'undefined'){
                   db.collection('accounts').doc(this.$route.params.id).update({
                     Mobile_Number: this.Primary_Contact.Mobile_Number,
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                  Modified_Byid: this.UserRecord.id,
                  Modified_On: new Date()
                     })
                 }
                
      }
      else{
        alert('you need to capture the values in '+this.UndefinedMandatoryFields)
        this.UndefinedMandatoryFields.map(field => {
          console.log('getting element by id '+field)
          let element = document.getElementById(field)
          
          element.setAttribute("class", "incompletedmandatory")
          console.log('here is element')
          console.log(element)
        })
      }
      },
    ViewActivityItem (item) {
        this.$router.push('/Activity/'+item.id)
      },

      ViewMapItem(PhysicalAddressStreetNumber,PhysicalAddressStreetName,PhysicalAddressSuburb,PhysicalAddressCity,PhysicalAddressPostalCode,PhysicalAddressStateProvince,PhysicalAddressCountryRegion) {
        let combinedlink = PhysicalAddressStreetNumber+'+'+PhysicalAddressStreetName+'+'+PhysicalAddressSuburb+'+'+PhysicalAddressCity+'+'+PhysicalAddressPostalCode+'+'+PhysicalAddressStateProvince+'+'+PhysicalAddressCountryRegion
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        window.open(FinalLink, '_blank')
      },

      deleteItem (item) {
        const index = this.AccountsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('accounts').doc(item.id).delete() && this.AccountsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.AccountsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.UndefinedMandatoryFields.map(prop => {
        
            let element = document.getElementById(prop)
              if(element){  
                var x = element.hasAttribute("class")
                if(x){        
                  element.removeAttribute("class")
                  }
                }
              
            })
              this.EditingSection1 = false
              this.EditingSection2 = false
              this.EditingSection3 = false
      },
    ResetSearch() {
      this.search = ''
    },
    
    CancelAccountEditing(){
      this.EditingAccount = false
    },
    EditAccount() {
      this.EditingAccount = !this.EditingAccount
    },
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if(mybutton){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          mybutton.style.display = "block";
        }
        else {
          mybutton.style.display = "none";
        }
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    NavigatetoTab(item){
      let tabelmnt = document.getElementById(item.Name)
      tabelmnt.scrollIntoView();
    },
    CancelSubmitSystemTicket(){
      this.TicketLog = false
      this.helpsheet = false
    },
    ActivateTicketLog(){
      this.TicketLog = true
    },
    
      
  GetBusinessUnitsBusiness_Unit() {
  db.collection('businessunits').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Business_UnitArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetBusinessUnitsBusiness_UnitArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.BusinessUnitsStore.length > 0){
      //alert('got from store')
      vm.Business_UnitArray = vm.BusinessUnitsStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setBusinessUnitsArray',
                    getter: 'getBusinessUnitsArray',
                    arrayname: 'BusinessUnitsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.BusinessUnitsStore)
                        vm.Business_UnitArray = vm.BusinessUnitsStore
                        return vm.GetBusiness_Unit();
                        })
    }
  },

  GetBusiness_Unit(){
    this.Business_Unit = this.Business_UnitArray.find(business_unit => business_unit.id === this.AccountData.Business_Unitid)
  },

  //GetBusinessUnitsBusiness_Unit(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Business_UnitArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetBusinessUnitsBusiness_Unit finished')
 //   })
 // })
//    })
//  },

  GetUsersCreated_By() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.Created_ByArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersCreated_ByArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.Created_ByArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.Created_ByArray = vm.UsersStore
                        return vm.GetCreated_By();
                        })
    }
  },

  GetCreated_By(){
    this.Created_By = this.Created_ByArray.find(created_by => created_by.id === this.AccountData.Created_Byid)
  },

  //GetUsersCreated_By(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Created_ByArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersCreated_By finished')
 //   })
 // })
//    })
//  },

  GetUsersOwner() {
  db.collection('users').onSnapshot(res => {

  const changes = res.docChanges();
  changes.forEach(change => {
    if (change.type === 'added') {
      this.OwnerArray.push({
        ...change.doc.data(),
        id: change.doc.id
      })
    }


  })
})
  },

  GetUsersOwnerArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.UsersStore.length > 0){
      //alert('got from store')
      vm.OwnerArray = vm.UsersStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setUsersArray',
                    getter: 'getUsersArray',
                    arrayname: 'UsersArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.UsersStore)
                        vm.OwnerArray = vm.UsersStore
                        return vm.GetOwner();
                        })
    }
  },

  GetOwner(){
    this.Owner = this.OwnerArray.find(owner => owner.id === this.AccountData.Ownerid)
  },

  //GetUsersOwner(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.OwnerArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetUsersOwner finished')
 //   })
 // })
//    })
//  },

  GetContactsPrimary_ContactArrayandLookupValue(query) {
  let newquery = query
  let vm = this
  if(vm.ContactsStore.length > 0){
      //alert('got from store')
      vm.Primary_ContactArray = vm.ContactsStore
    }
    else{
      //alert('NOPE, MUST CALL from store')
      let payload = {
              
                    query: query,
                    arraymutation: 'setContactsArray',
                    getter: 'getContactsArray',
                    arrayname: 'ContactsArray'
                  }
                  this.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                        console.log(vm.ContactsStore)
                        vm.Primary_ContactArray = vm.ContactsStore
                        return vm.GetPrimary_Contact();
                        })
    }
  },

  GetPrimary_Contact(){
    this.Primary_Contact = this.Primary_ContactArray.find(primary_contact => primary_contact.id === this.AccountData.Primary_Contactid)
  },

  //GetContactsPrimary_Contact(query) {
    //let vm = this
    //return new Promise(function(resolve, reject) {
   // query.get().then(res => {
   // const changes = res.docChanges();
   // changes.forEach(change => {
     // if (change.type === 'added') {
       // vm.Primary_ContactArray.push({
         // ...change.doc.data(),
        //  id: change.doc.id
      //  })
   //   }
  //  resolve('Method GetContactsPrimary_Contact finished')
 //   })
 // })
//    })
//  },



CancelAddParentDialog(){
  this.AddNewParentLookupDialog = false
  this.ParentTitle = ''
  this.ParentCollection = ''
  this.NewParentPrimary = ''
  this.ParentLookup = ''
  this.NewParentAdditional = []
  this.FieldFilter = ''
  this.LookupArray = []
  this.LookupArrayHeaders = []
},

CancelSelection(){
  this.ViewExistingLookupDialog = false
},

LinkParent(parent){
  console.log('we will link this one',parent)
  console.log('array',this[this.ParentLookup.Array])
  let parentdisplay = this[this.ParentLookup.Array].find(obj => obj.id === parent[this.ParentLookup.Propid]) 
  this[this.ParentLookup.LookupFieldName] = parentdisplay
  console.log('to this one like this',this[this.ParentLookup.LookupFieldName])
},
PrepareLinkExistingBusiness_Unit(){
this.CancelAddParentDialog()
this.PrepareDataParentBusiness_Unit()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Business Unit'
      this.LookupArray = this.Business_UnitArray
      this.LookupArrayHeaders = [
        { text: 'Description', value: 'Description',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
      ]
},

PrepareDataParentBusiness_Unit(){
  this.ParentTitle = 'Business_Unit'
  this.ParentCollection = 'businessunits'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Business Unit', Prop: 'Name', Propid: 'Business_Unitid', Value: '',Array: 'Business_UnitArray',LookupFieldName: 'Business_Unit'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Business Unit', Prop: 'Business_Unit', Value: '',LookupFieldName: 'Business_Unit'}     
  
},

ActivateAddNewBusiness_UnitDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentBusiness_Unit()
},


PrepareLinkExistingCreated_By(){
this.CancelAddParentDialog()
this.PrepareDataParentCreated_By()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Created By'
      this.LookupArray = this.Created_ByArray
      this.LookupArrayHeaders = [
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
      ]
},

PrepareDataParentCreated_By(){
  this.ParentTitle = 'Created_By'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Created By', Prop: 'Full_Name', Propid: 'Created_Byid', Value: '',Array: 'Created_ByArray',LookupFieldName: 'Created_By'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Created By', Prop: 'Created_By', Value: '',LookupFieldName: 'Created_By'}
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )     
  
},

ActivateAddNewCreated_ByDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentCreated_By()
},


PrepareLinkExistingOwner(){
this.CancelAddParentDialog()
this.PrepareDataParentOwner()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Owner'
      this.LookupArray = this.OwnerArray
      this.LookupArrayHeaders = [
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Is Team Activity Manager', value: 'Is_Team_Activity_Manager',class: "overline"},
        { text: 'Manager', value: 'Manager.Full_Name',class: "overline"},
        { text: 'Name', value: 'Name',class: "overline"},
        { text: 'Profile Photo', value: 'Profile_Photo',class: "overline"},
        { text: 'Surname', value: 'Surname',class: "overline"},
      ]
},

PrepareDataParentOwner(){
  this.ParentTitle = 'Owner'
  this.ParentCollection = 'users'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Owner', Prop: 'Full_Name', Propid: 'Ownerid', Value: '',Array: 'OwnerArray',LookupFieldName: 'Owner'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Owner', Prop: 'Owner', Value: '',LookupFieldName: 'Owner'}
        let Is_Team_Activity_ManagerObj = {Type: 'Boolean', Name: 'Is Team Activity Manager',Prop: 'Is_Team_Activity_Manager'}
  this.NewParentAdditional.push(Is_Team_Activity_ManagerObj )
        let ManagerObj = {Type: 'Lookup', Name: 'Manager',Prop: 'Full_Name',Array: this.ManagerArray, Propid: 'Full_Nameid', LookupFieldName: 'Full_Name', Value: ''}
  this.NewParentAdditional.push(ManagerObj )
        let NameObj = {Type: 'Single Line Text', Name: 'Name',Prop: 'Name'}
  this.NewParentAdditional.push(NameObj )
        let Profile_PhotoObj = {Type: 'Single File Upload', Name: 'Profile Photo',Prop: 'Profile_Photo'}
  this.NewParentAdditional.push(Profile_PhotoObj )
        let SurnameObj = {Type: 'Single Line Text', Name: 'Surname',Prop: 'Surname'}
  this.NewParentAdditional.push(SurnameObj )     
  
},

ActivateAddNewOwnerDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentOwner()
},


PrepareLinkExistingPrimary_Contact(){
this.CancelAddParentDialog()
this.PrepareDataParentPrimary_Contact()
      this.ViewExistingLookupDialog = true
      this.FieldFilter = 'Primary Contact'
      this.LookupArray = this.Primary_ContactArray
      this.LookupArrayHeaders = [
        { text: 'Business Unit', value: 'Business_Unit.Name',class: "overline"},
        { text: 'Company', value: 'Company.Company_Name',class: "overline"},
        { text: 'Full Name', value: 'Full_Name',class: "overline"},
        { text: 'Owner', value: 'Owner.Full_Name',class: "overline"},
      ]
},

PrepareDataParentPrimary_Contact(){
  if(!this.CompanyArray || this.CompanyArray.length === 0){
  this.UserRoles.map(role => {
              let AccountsCompanyCollectionReference = db.collection('accounts')
              let allAccountsCompanylistRoles = [
  "Default All",
  "Default list All"
]
              let allAccountsCompanytest = allAccountsCompanylistRoles.find(obj => obj == role)
              let peerAccountsCompanylistRoles = [
  "Default Unit Down",
  "Default list UnitDown"
]
              let peerAccountsCompanytest = peerAccountsCompanylistRoles.find(obj => obj == role)
              let parentAccountsCompanylistRoles = [
  "Default Unit",
  "Default list UserUnit"
]
              let parentAccountsCompanytest = parentAccountsCompanylistRoles.find(obj => obj == role)
              let childAccountsCompanylistRoles = [
  "Default Owner"
]
              let childAccountsCompanytest = childAccountsCompanylistRoles.find(obj => obj == role)
              if(typeof allAccountsCompanytest !== 'undefined' || vm.IsAdmin === true){                    
                db.collection('accounts').onSnapshot(res => {
                  const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      this.CompanyArray.push({
                        ...change.doc.data(),
                        id: change.doc.id
                      })
                    }
                  })
                })
               
              }
              else if(typeof peerAccountsCompanytest !== 'undefined'){
                let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Business_Unitid', 'in', vm.UserBusUnitChildren);
                db.collection('accounts').where('Business_Unitid', 'in', vm.UserBusUnitChildren).onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        this.CompanyArray.push({
                          ...change.doc.data(),
                          id: change.doc.id
                        })
                      }
                    })
                  })
              }
              else if(typeof parentAccountsCompanytest !== 'undefined'){
                let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Business_Unitid', '==', vm.UserBusinessUnitID);
                db.collection('accounts').where('Business_Unitid', '==', vm.UserBusinessUnitID).onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        this.CompanyArray.push({
                          ...change.doc.data(),
                          id: change.doc.id
                        })
                      }
                    })
                  })
              }
              else if(typeof childAccountsCompanytest !== 'undefined'){
                let AccountsCompanyQuery =  AccountsCompanyCollectionReference.where('Ownerid', '==', vm.UserRecord.id);
                db.collection('accounts').where('Ownerid', '==', vm.UserRecord.id).onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        this.CompanyArray.push({
                          ...change.doc.data(),
                          id: change.doc.id
                        })
                      }
                    })
                  })
              }
              else {
                console.log('actually user has no access to some of the related fields')
                //vm.RoutetoErrorPage()
              }
            })
  }
  this.ParentTitle = 'Primary_Contact'
  this.ParentCollection = 'contacts'
  this.NewParentAdditional = []
  this.ParentLookup = {Type: 'Lookup', Name: 'Primary Contact', Prop: 'Full_Name', Propid: 'Primary_Contactid', Value: '',Array: 'Primary_ContactArray',LookupFieldName: 'Primary_Contact'}
  this.NewParentPrimary = {Type: 'Lookup', Name: 'Primary Contact', Prop: 'Primary_Contact', Value: '',LookupFieldName: 'Primary_Contact'}
        let CompanyObj = {Type: 'Lookup', Name: 'Company',Prop: 'Company_Name',Array: this.CompanyArray, Propid: 'Company_Nameid', LookupFieldName: 'Company_Name', Value: ''}
  this.NewParentAdditional.push(CompanyObj )     
  
},

ActivateAddNewPrimary_ContactDialog(){
  this.CancelSelection()
  this.AddNewParentLookupDialog = true
  this.PrepareDataParentPrimary_Contact()
},



      
    
      
      
    
      
    
      
    GetMobile_Number(docid){
      console.log('calling Mobile_Number with '+docid)
      db.collection('').doc(docid).onSnapshot(snapshot => {
        this.Primary_Contact = snapshot.data()
        this.Mobile_Number = this.Primary_Contact.Mobile_Number
      })
    },
    
      
            
      
    
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
    
          DateFilterString(date){      
      let onedate = date.toDate()
      let dateyear = onedate.getFullYear().toString()
      let datemonth = onedate.getMonth()-1+2
      let datemonthfinal = datemonth.toString()
      let dateday = onedate.getDate()
      if(datemonth.toString().length===1){
        let stringdate = dateyear+'0'+datemonth+dateday
      return stringdate
      }
      else{
        let stringdate = dateyear+datemonth+dateday
      return stringdate
      }
    },
    AddNote(){
      let NewNote = {
        Title: 'New Note',
        Owner: {Full_Name: this.UserRecord.Full_Name, id:this.UserRecord.id},
        Description: 'Add Description to the Note',
        Color: '#ECEC93FF',
        TitleColor: '#EBEB6C',
        Dark: false
      }
      this.NotesArray.push(NewNote)
      this.UpdateNotesArray()
    },
    LightenColor(note) {
    let color = note.Color
  	let num = parseInt(color.replace("#",""), 16)
		let amt = Math.round(2.55 * 25)
		let R = (num >> 16) - amt
		let B = (num >> 8 & 0x00FF) - amt
		let G = (num & 0x0000FF) - amt;

		note.TitleColor = (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    },
    ToggleNoteDark(note){
      if(note.Dark === true){
        note.Dark = false
        this.UpdateNotesArray()
      }
      else{
        note.Dark = true
        this.UpdateNotesArray()
      }
    },
    UpdateNotesArray(){
      db.collection('accounts').doc(this.$route.params.id).update({
        notesarray: this.NotesArray,
        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
        Modified_Byid: this.UserRecord.id,
        Modified_On: new Date()
      })
    },
    
    SaveNewTask(){
      const TasktoAdd = {
            Name: this.NewTask.Name,
            description: this.NewTask.description,
            activitytype: 'Task',
            regardingtype: 'Account',
            createdon: format(new Date(),'yyyy-MM-dd'),
            duedate: this.DateFormatter(this.NewTask.duedate),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            creatorfullname: this.UserRecord.Full_Name,
            creatorid: this.UserRecord.id,
            status: 'Open',
            regardingrecord: {
              Link:'/Account/'+this.$route.params.id, Name:this.Company_Name, Company_Name:this.Company_Name,id: this.$route.params.id
            },

            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
      }
      if(typeof this.Business_Unit.id !== 'undefined'){
        TasktoAdd.Business_Unitid = this.Business_Unit.id
      }
      db.collection('activities').add(TasktoAdd).then(doc => {

             if(this.NewTask.activitytype === 'Task'){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Name,
              Content: TasktoAdd.description,
              CreatorFullName: TasktoAdd.creatorfullname,
              CreatorID: TasktoAdd.creatorid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/',
              Message: 'a New Task has been assigned to you by '+TasktoAdd.creatorfullname
            }
            db.collection('notifications').add(NewNotification).then(notedoc => {
            let tempPath = '/Activity/'+doc.id
            db.collection('notifications').doc(notedoc.id).update({
              Path: tempPath
            })
            })
             }


          this.CloseTaskDialog()
          })
    },

    CloseTaskDialog(){
      this.NewTask = Object.assign({},this.DefaultNewTask)
      this.TaskDialog = false
    },

    GetActivities( regardingquery) {

      regardingquery.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
  },
      
      
     
      
      

      
      
      
      ResetFilters() {},
      
      
      
    ProcessDeleteItem(item,itemarrayname,collection){
      let arrayindex = this[itemarrayname].indexOf(item)
      this[itemarrayname].splice(arrayindex,1)
      let itemref = firebase.storage().ref(item.StorageRef);
      itemref.delete().then(function() {									
          // File deleted successfully			
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
        }).catch(function(error) {									
          // Uh-oh, an error occurred!									
        });	
      
    },
    MoveFiletoNewLocation(currentdownloadurl, destinationPath) {									
          let vm = this									
         return new Promise(function(resolve, reject) {									
       									
        fetch(currentdownloadurl,{									
                      method: 'GET', // *GET, POST, PUT, DELETE, etc.									
                      mode: 'cors', // no-cors, *cors, same-origin									
                  }).then(htmlReturn => {									
                    let blob = htmlReturn.blob().then(b => {									
                      console.log(destinationPath)									
                      var storageRef = firebase.storage().ref(destinationPath)									
                      var uploadTask = storageRef.put(b);									
                      uploadTask									
                          .then(snapshot => snapshot.ref.getDownloadURL())									
                            .then((url) => {									
                              resolve(url)									
                  })									
                })									
            })									
            // let storageRef = firebase.storage().ref("Unsorted");									
            // let fileref = storageRef.child(item.Name)									
            // fileref.delete()									
         })	
    },
    RemoveSingleItemfromTable(item,itemarrayname,collection){
      console.log(this.selectedEntity)
        const NewDoc = {									
                Name: item.Name,									
                FileType: item.FileType,									
                OldPath: 'No Reference',																		
                url: item.url,									
                Status: {									
                ID: 1000003,									
                Name: 'Assign Table'},                								
                Created_By: item.Created_By,
                Created_Byid: item.Created_Byid,          
                Created_On: item.Created_On,	
                ModifiedDate: item.ModifiedDate,
                Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},
                Modified_Byid: this.UserRecord.id,
                Modified_On: new Date(),
                Record: this.selectedRecord,
                RecordName: this.selectedRecord.Job_Number,
                Entity: this.selectedEntity
                
                								
              									
            }
            
        NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.$route.params.id+'/Unsorted/'+ NewDoc.Name		
        this.MoveFiletoNewLocation(item.fileurl, NewDoc.StorageRef).then(url => {
          NewDoc.url = url			
          console.log(NewDoc)		
          console.log(item)				
                      db.collection('RMDocuments').add(NewDoc).then(doc => {									
                        console.log('okay loaded it')									
                    })
          let itemref = firebase.storage().ref(item.StorageRef);
          itemref.delete().then(function() {									
              // File deleted successfully			
            }).catch(function(error) {									
              // Uh-oh, an error occurred!									
            });	
          let arrayindex = this[itemarrayname].indexOf(item)
          this[itemarrayname].splice(arrayindex,1)
          db.collection(collection).doc(this.$route.params.id).collection(itemarrayname.toLowerCase()).doc(item.id).delete()
          db.collection(collection).doc(this.$route.params.id).collection('RMUnsortedDocuments').add(NewDoc).then(doc => {
            NewDoc.id = doc.id
            this[itemarrayname].push(NewDoc)
          console.log('added new item')
          })
        })				
    },
    
      
      
  
        
        
        
        
        
      
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    AssignBU(){
      let userid = this.Owner.id
      db.collection('users').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        this.Business_Unit = BUdata
        this.Business_Unit.id = BUId
      })
      })
    },
    GetBusinessUnits(){
      db.collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    
    

    
      
  }
}


</script>

<style>

#mySidenav a {
  position: fixed;
  right: -80px;
  transition: 0.3s;
  padding: 15px;
  width: 150px;
  text-decoration: none;
  font-size: 12px;
  color: white;
  border-radius: 0 5px 5px 0;
  top: 55px;
}

#mySidenav a:hover {
  right: 0;
}

#about {
  
  background-color: #4CAF50;
}

.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}
.fab-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 100
}
.stickytopright {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 100
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 100
}
.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
.HTMLTable{
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}

      
</style>
  
            
        